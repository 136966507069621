import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { split } from 'lodash';
import { StaticContentHelper } from '../helpers/static-content-helper';
import { StaticContentService } from '../services/static-content.service';
import { MenuCategory } from './entities/menuCategory';
import { Page } from './entities/page';
import { StaticContent } from './entities/staticContent';
import { SubSection } from './entities/subSection';

@Component({
  selector: 'app-static-content-page',
  templateUrl: './static-content-page.component.html',
  styleUrls: ['./static-content-page.component.scss']
})
export class StaticContentPageComponent implements OnInit, AfterViewInit {
  public showPageNotFound: boolean;
  observer: MutationObserver;

  constructor(private translateService: TranslateService, private staticContentService: StaticContentService, private elRef: ElementRef, private titleService: Title) { }

  public staticContent: StaticContent;

  public subsection: SubSection;

  public category: MenuCategory;

  public page: Page;

  public pageHtml: string;

  public showPage: boolean = false;

  public noPageFound: boolean = false;

  public interval: any;

  public cssJsSet: boolean = false;

  public cdnPath: string;

  ngOnInit(): void {
    this.cdnPath = this.staticContentService.cdn();
    this.interval = setInterval(() => {
      if (!this.cssJsSet)
      {

      this.staticContent = this.staticContentService.staticContent;
      
      if (!!this.staticContent)
      {
        var axdFiles = this.staticContent.GlobalCss.split(";");
        axdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "css");
        });
        var jsAxdFiles = this.staticContent.GlobalJs.split(";");
        jsAxdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "js");
        });
        this.setPageContent();
        clearInterval(this.interval);
        this.cssJsSet = true;
        return;
      }

      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.setPageContent();
      });
    }
    }, 500);

  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));                               
      });   
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public sleepFor(sleepDuration){
    var now = new Date().getTime();
    while(new Date().getTime() < now + sleepDuration){ 
        /* Do nothing */ 
    }
}

  public traversePageForCorrectUrl(index: number) {

    var splitPath = window.location.pathname.split("/");
    splitPath.shift();

    //if split path array length equals the index that means it's within this page otherwise drill down to the next page.
    if (index == splitPath.length)
    {
      //We're now on the correct index.
      if (!!this.page && this.page.Url == `${window.location.pathname}/`)
      { 
        if (!!this.page.Css)
        {
            var cssFiles = this.page.Css.split(";");
            cssFiles.forEach(x => this.staticContentService.loadjscssfile(x, "css"));
        }

        //console.log(htmlPageString);

        if (!!this.page.Js)
        {
          this.page.Js = this.page.Js.replace('\r\n','');
          var jsFiles = this.page.Js.split(";");
          jsFiles.forEach(x => this.staticContentService.loadjscssfile(x, "js"));
        }

        var htmlPageString: string = "";
        this.page.Modules.forEach(x => htmlPageString = htmlPageString.concat(x.Html));
        htmlPageString = this.decodeHtml(htmlPageString);

        //This should ideally be done in Umbraco so it's consistent and we don't have to replace so many variants
        var firstReg = /img src=\"/g;
        htmlPageString = htmlPageString.replace(firstReg, `img src=\"${this.cdnPath}/`);

        var secondReg = /img src='\//g;
        htmlPageString = htmlPageString.replace(secondReg, `img src='${this.cdnPath}/`);

        var thirdReg = /img src=\\\""/g;
        htmlPageString = htmlPageString.replace(thirdReg, `img src=\\\"${this.cdnPath}/`);

        var fourthReg = /background-image:url\('\//g;
        htmlPageString = htmlPageString.replace(fourthReg, `background-image:url('${this.cdnPath}/`);

        var pdfReg = /href='\/media\//g;
        htmlPageString = htmlPageString.replace(pdfReg, `href='${this.cdnPath}/media/`);

        var pdfReg2 = /href="\/media\//g;
        htmlPageString = htmlPageString.replace(pdfReg2, `href=\"${this.cdnPath}/media/`);

        // htmlPageString = this.decodeHtml(htmlPageString);
        this.pageHtml = htmlPageString;

        this.showPage = true;
      }
      else
      {
        this.showPageNotFound = true;
      }
    }
    else{
      //If index is 2 that means we're looking at a page under a category and not a child page
      if (index == 2)
      {
        for (let i = 0; i < this.category.Pages.length; i++) {
          var categoryPageUrl = this.category.Pages[i].Url;
          if (!!categoryPageUrl)
          {
            var categoryPageUrlSplit = categoryPageUrl.split("/");
            categoryPageUrlSplit.shift();
            if (splitPath[index] == categoryPageUrlSplit[index] && splitPath[index+1] == categoryPageUrlSplit[index+1])
            {
              this.page = this.category.Pages[i];
              index++;
              this.traversePageForCorrectUrl(index);
            }
          }
        }
        if(!this.page)
        {
          this.showPageNotFound = true;
        }
      }
      else if (index <= splitPath.length) {
          for (let i = 0; i < this.page?.Pages?.length; i++) {
            var pageUrl = this.page.Pages[i].Url;
            if (!!pageUrl)
            {
              var pageUrlSplit = pageUrl.split("/");
              pageUrlSplit.shift();
              if (splitPath[index] == pageUrlSplit[index] && splitPath[index+1] == pageUrlSplit[index+1])
              {
                this.page = this.page.Pages[i];
              }
            }
          }
        index++;
        this.traversePageForCorrectUrl(index);
      }
      else
      {
        this.showPageNotFound = true;
      }

    }
  }

public decodeHtml(html: string)
{
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

public setPageContent()
{
  var tabTitle = this.staticContentService.getPageTabTitle();
  this.titleService.setTitle(tabTitle);
  this.subsection = null;
  this.pageHtml = null;
  var splitPath = window.location.pathname.split("/");

  //greater than 1 because the first element is empty and the second is always the language so there's no point if there's only 2 elements
  if (!!splitPath && splitPath.length > 2) {
    splitPath.shift();

    //Loop through the top level section to determine language
    var languageSection = this.staticContent.Sections.find(languageSection => languageSection.Url == `/${splitPath[0]}/`);

    if (!!languageSection) {
      //Look into which subsections urls match the second parameter
      // languageSection.SubSections.find(x => x.Url.split("/")[2] == splitPath[1] )

      var subSectionUrls = languageSection.SubSections.map(x => x.Url);

      for (let i = 0; i < subSectionUrls.length; i++) {
        var splitSubSectionUrl = subSectionUrls[i].split("/");
        if (!!splitSubSectionUrl && splitSubSectionUrl.length > 2)
        {
          splitSubSectionUrl.shift();
          if (splitSubSectionUrl[1] == splitPath[1])
          {
            this.subsection = languageSection.SubSections[i];
          }
        }
        else
        {
          this.showPageNotFound = true;
          return;
        }
      }


      if (!!this.subsection && splitPath.length > 2)
      {
        var categoryUrls = this.subsection.Categories.map(x => x.Url);

        for (let i = 0; i < categoryUrls.length; i++) {
          var splitCategoryUrls = categoryUrls[i].split("/");
          if (!!splitCategoryUrls && splitCategoryUrls.length > 3)
          {
            splitCategoryUrls.shift();
            if (splitCategoryUrls[2] == splitPath[2])
            {
              this.category = this.subsection.Categories[i];
            }
          }
        }
        if (!this.category)
        {
          this.showPageNotFound = true;
          return;
        }
      }
      else{
        this.showPageNotFound = true;
        return;
      }

      this.traversePageForCorrectUrl(2);
    }
    else
    {
      this.showPageNotFound = true;
    }
  }
  else
  {
    this.showPageNotFound = true;
  }
}

}

