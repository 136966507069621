import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { StaticContent } from '../../shared/static-content/entities/staticContent';
// import { LanguageService } from '../../shared/services/language.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { StaticContentService } from '../../shared/services/static-content.service';
import { Page } from '../../shared/static-content/entities/page';
import { RoleService } from '../../account/services/role.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home-personal',
  templateUrl: './home-personal.component.html',
  styleUrls: ['./home-personal.component.scss']
})
export class HomePersonalComponent implements OnInit, AfterViewInit {

  public staticContent: StaticContent

  public modules: any;

  public mainImage: string;

  public imageUrlFormatted: boolean = false;

  public trackTraceInput: string;

  public pageHtml: string;

  public showHtmlModules: boolean;

  interval: any;

  public mainImageModule: any;

  public item1Icon: any;
  private item1DefaultIcon: any;
  private item1HoverIcon: any;

  public item2Icon: any;
  private item2DefaultIcon: any;
  private item2HoverIcon: any;

  public item3Icon: any;
  private item3DefaultIcon: any;
  private item3HoverIcon: any;

  onMouseOver(index: number): void {
    switch (index) {
      case 1:
        if(this.item1HoverIcon){
          this.item1Icon = this.item1HoverIcon;
        }
        break;
      case 1:
        if(this.item2HoverIcon){
          this.item2Icon = this.item2HoverIcon;
        }
        break;
      case 3:
        if(this.item3HoverIcon){
          this.item3Icon = this.item3HoverIcon;
        }
        break;
      default:
        break;
    }
  }

  onMouseOut(index: number): void {
    switch (index) {
      case 1:
        if(this.item1DefaultIcon){
          this.item1Icon = this.item1DefaultIcon;
        }
        break;
      case 1:
        if(this.item2DefaultIcon){
          this.item2Icon = this.item2DefaultIcon;
        }
        break;
      case 3:
        if(this.item3DefaultIcon){
          this.item3Icon = this.item3DefaultIcon;
        }
        break;
      default:
        break;
    }
  }

  public role: string;

  public cdnPath: string;
  observer: any;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private roleService: RoleService,
    private staticContentService: StaticContentService,
    private elRef: ElementRef,
    private titleService: Title) { }

  ngOnInit(): void {

    this.cdnPath = this.staticContentService.cdn();

    this.interval = setInterval(() => {
      this.staticContent = this.staticContentService.staticContent;
      if (!!this.staticContent) {
        var axdFiles = this.staticContent.GlobalCss.split(";");
        axdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "css");
        });
        var jsAxdFiles = this.staticContent.GlobalJs.split(";");
        jsAxdFiles.forEach(x => {
          this.staticContentService.loadjscssfile(x, "js");
        });
        this.setPageContentForLanguage();
        clearInterval(this.interval);
      }
    }, 500);

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setPageContentForLanguage();
    });
  }

  ngAfterViewInit() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        window.dispatchEvent(new Event('th.domChanged'));
      });
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.elRef.nativeElement, config);
  }

  public onTrackTraceEnter(){
    this.router.navigate([`${this.translateService.currentLang}/${this.roleService.role()}/send-receive/track-trace`, this.trackTraceInput]);
  }

  private setPageContentForLanguage() {
    var languageSection;
    var privateSection;
    var homeCategory;
    var homepage;

    var routeSplit = this.router.url.split("/");
    if (!!routeSplit && routeSplit.length > 2)
    {
      this.role = routeSplit[2];
    }

    if (!!this.staticContent && !!this.role) {
      if (this.translateService.currentLang == "en") {
        languageSection = this.staticContent.Sections.find(x => x.Url = "/en/");
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/en/${this.role}/`);
          if (!!privateSection) {
            homeCategory = privateSection.Categories.find(x => x.Url == `/en/${this.role}/home/`);
          }
          if (!!homeCategory && !!homeCategory.Pages) {
            homepage = homeCategory.Pages.find(x => x.Url = `/en/${this.role}/home/home/`);
          }
        }
      }
      if (this.translateService.currentLang == "fo") {
        languageSection = this.staticContent.Sections.find(x => x.Url == "/fo/");
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/fo/${this.role}/`);
          if (!!privateSection) {
            homeCategory = privateSection.Categories.find(x => x.Url == `/fo/${this.role}/home/`);
          }
          if (!!homeCategory && !!homeCategory.Pages) {
            homepage = homeCategory.Pages.find(x => x.Url = `/fo/${this.role}/home/home/`);
          }
        }
      }

      if (!!homepage && !!homepage.Modules) {
        var seo = homepage.SEO;
        if(seo){
          var tabTitle = seo.MetaTitle;
          this.titleService.setTitle(tabTitle);
        }
        else{
          this.titleService.setTitle("Posta");
        }

        this.modules = homepage.Modules;

        for (var i = 0; i < this.modules.length; i++) {
          if (!!this.modules[i].Content) {
            this.modules[i].Content.Image = this.formatImageUrl(this.modules[i].Content)
          }
        }

        this.mainImageModule = this.modules.find(x => x.ModuleType == "psModule");

        if (!!this.mainImageModule) {
          this.mainImage = this.mainImageModule.Content.Image;
          this.item1Icon = `${this.cdnPath}${this.mainImageModule.Content.Item1Icon}`;
          this.item1DefaultIcon = `${this.cdnPath}${this.mainImageModule.Content.Item1Icon}`;
          if(this.mainImageModule.Content.Item1HoverIcon)
            this.item1HoverIcon = `${this.cdnPath}${this.mainImageModule.Content.Item1HoverIcon}`;

          this.item2Icon = `${this.cdnPath}${this.mainImageModule.Content.Item2Icon}`;
          this.item2DefaultIcon = `${this.cdnPath}${this.mainImageModule.Content.Item2Icon}`;
          if(this.mainImageModule.Content.Item2HoverIcon)
            this.item2HoverIcon = `${this.cdnPath}${this.mainImageModule.Content.Item2HoverIcon}`;
          
          this.item3Icon = `${this.cdnPath}${this.mainImageModule.Content.Item3Icon}`;
          this.item3DefaultIcon = `${this.cdnPath}${this.mainImageModule.Content.Item3Icon}`;
          if(this.mainImageModule.Content.Item3HoverIcon)
            this.item3HoverIcon = `${this.cdnPath}${this.mainImageModule.Content.Item3HoverIcon}`;
        }

        this.setHtmlModulePages(homepage);
      }
    }

    // var columnModules = this.modules.filter(x => x.ModuleTypeId == 107);
    // if (!!columnModules) {
    //   columnModules.forEach(x => x.Content.Image = this.formatImageUrl(x.Content.Image));
    // }

    return languageSection;
  }

  formatImageUrl(content: any) {
    if (!!content.Image && content.Image.includes(`${this.cdnPath}`)) {
      return content.Image;
    }
    else if (!!content.Image) {
      return `${this.cdnPath}/${content.Image}`;
    }
    return undefined;
  }

  public setHtmlModulePages(homepage: Page) {
    // if (!!homepage.Css) {
    //   var cssFiles = homepage.Css.split(";");
    //   cssFiles.forEach(x => this.staticContentHelper.loadjscssfile(x, "css"));
    // }

    //console.log(htmlPageString);

    // if (!!homepage.Js) {
    //   homepage.Js = homepage.Js.replace('\r\n', '');
    //   var jsFiles = homepage.Js.split(";");
    //   jsFiles.forEach(x => this.staticContentHelper.loadjscssfile(x, "js"));
    // }

    var htmlPageString: string = "";

    var htmlModules = homepage.Modules.filter(x => x.ModuleType == "moduleHtml");
    htmlModules.forEach(x => htmlPageString = htmlPageString.concat(x.Html));
    htmlPageString = this.decodeHtml(htmlPageString);

    // var firstReg = /img src=\"/g;
    // htmlPageString = htmlPageString.replace(firstReg, `img src=\"${this.cdnPath}/"`);
    // htmlPageString = htmlPageString.replace("img src='/", `img src='${this.cdnPath}/`);

    // var secondReg = /img src=\\\""/g;
    // htmlPageString = htmlPageString.replace(secondReg, `img src=\\\"${this.cdnPath}/`);

    // var thirdReg = /background-image:url\('\//g;
    // htmlPageString = htmlPageString.replace(thirdReg, `background-image:url('${this.cdnPath}/`);
    // // htmlPageString = this.decodeHtml(htmlPageString);
    // this.pageHtml = htmlPageString;

    var firstReg = /img src=\"/g;
    htmlPageString = htmlPageString.replace(firstReg, `img src=\"${this.cdnPath}/`);

    var secondReg = /img src='\//g;
    htmlPageString = htmlPageString.replace(secondReg, `img src='${this.cdnPath}/`);

    var thirdReg = /img src=\\\""/g;
    htmlPageString = htmlPageString.replace(thirdReg, `img src=\\\"${this.cdnPath}/`);

    var fourthReg = /background-image:url\('\//g;
    htmlPageString = htmlPageString.replace(fourthReg, `background-image:url('${this.cdnPath}/`);

    var pdfReg = /a href='\/media\//g;
    htmlPageString = htmlPageString.replace(pdfReg, `a href='${this.cdnPath}/media/`);

    var pdfReg2 = /a href="\/media\//g;
    htmlPageString = htmlPageString.replace(pdfReg2, `a href=\"${this.cdnPath}/media/`);
    // htmlPageString = this.decodeHtml(htmlPageString);
    this.pageHtml = htmlPageString;

    this.showHtmlModules = true;
  }

  public decodeHtml(html: string) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
}
