import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { StaticContent } from '../static-content/entities/staticContent';
import { environment} from '../../../environments/environment';
import { RoleService } from '../../account/services/role.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class StaticContentService {
    public staticContent: StaticContent;
    public staticContentHash: string;

    constructor(private httpClient: HttpClient,
      private roleService: RoleService,
      private translateService: TranslateService,
      private titleService: Title,
      private router: Router) {
    }

    setStaticContent(staticContent: any) {
        this.staticContent = staticContent;
    }

    setStaticContentHash(x: any) {
        this.staticContentHash = x;
    }

    public getStaticContent(): Observable<StaticContent> {
        return this.httpClient.get(`${environment.apiUrl}/api/StaticContent/StaticJson`) as Observable<StaticContent>;
    }

    public getTranslationDictionary(language: string): Observable<StaticContent> {
        return this.httpClient.get(`${environment.apiUrl}/api/StaticContent/TranslationDictionary/${language}`) as Observable<StaticContent>;
    }

    public getHash(): Observable<string> {
        return this.httpClient.get(`${environment.apiUrl}/api/StaticContent/Hash`) as Observable<string>;
    }
  
    cdn(): string {
      if (!!this.staticContent) {
        return this.staticContent.CDN;
      }
      return 'https://dkxtfyil83pr6.cloudfront.net';
    }
  
    getPaths(): string[] {
      var href = this.router.url;
      var paths = href.split("/");

      paths.splice(0,3);

      for (var index in paths){
        if(paths[index].includes("?")){
          paths[index] = paths[index].slice(0, paths[index].indexOf("?"));
        }
      }

      // if(paths[0].includes("?returnUrl")){
      //   paths[0] = paths[0].slice(0, paths[0].indexOf("?returnUrl"));
      // }
      // else if(paths[0].includes("?")){
      //   paths[0] = paths[0].slice(0, paths[0].indexOf("?"));
      // }

      return paths;
    }

    getPageTitle(): string {
      var staticContent = this.staticContent;
      var href = this.router.url;
  
      var paths = this.getPaths();
      //if (paths.length < 5) {
        // this should have at least of 5
      //  return;
      //}
      // paths.splice(0, 3);
  
      var languageSection;
      var privateSection;
      var category;
      var page;
  
      //Do this so in case we have more a length of more than 2 in paths then it's easier to refactor to account for it.
  
      var role = this.roleService.role();
  
      if (!!staticContent && !!role) {
        languageSection = staticContent.Sections.find(x => x.Url == `/${this.translateService.currentLang}/`);
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/${this.translateService.currentLang}/${role}/`);
          if (!!privateSection) {
            category = privateSection.Categories.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/`);
          }
          if (!!category && !!category.Pages) {
            page = category.Pages.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/${paths[1]}/`);
          }
          else if (!!category && !!category.Modules){
            page = category;
          }
        }
  
        if (!!page && !!page.Modules) {
          // Set Tab Title
          var seo = page.SEO;
          if(seo){
            var tabTitle = seo.MetaTitle;
            this.titleService.setTitle(tabTitle);
          }
          else{
            this.titleService.setTitle("Posta");
          }

          // Agreed that the first html module will always be the title
          var firstHtmlModule = page.Modules.find(x => x.ModuleType == 'moduleHtml');
  
          if (!!firstHtmlModule) {
            var htmlPageString = this.decodeHtml(firstHtmlModule.Html);
  
            htmlPageString = this.replaceLinks(htmlPageString);
  
            return htmlPageString;
          }
        }
      }
      return '';
    }

    getPageTabTitle(): string {
      var staticContent = this.staticContent;
      var href = this.router.url;
  
      var paths = this.getPaths();
      //if (paths.length < 5) {
        // this should have at least of 5
      //  return;
      //}
      // paths.splice(0, 3);
  
      var languageSection;
      var privateSection;
      var category;
      var page;
  
      //Do this so in case we have more a length of more than 2 in paths then it's easier to refactor to account for it.
  
      var role = this.roleService.role();
  
      if (!!staticContent && !!role) {
        languageSection = staticContent.Sections.find(x => x.Url == `/${this.translateService.currentLang}/`);
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/${this.translateService.currentLang}/${role}/`);
          if (!!privateSection) {
            category = privateSection.Categories.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/`);
          }
          if (!!category && !!category.Pages) {
            page = category.Pages.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/${paths[1]}/`);
          }
          else if (!!category && !!category.Modules){
            page = category;
          }
        }
  
        if (!!page) {
          var seo = page.SEO;
          var tabTitle = seo.MetaTitle;
          return tabTitle;
        }
      }
      return 'Posta';
    }
  
    getImageForPsModule(): string {
      var staticContent = this.staticContent;
  
      var languageSection;
      var privateSection;
      var category;
      var page;
  
      //Do this so in case we have more a length of more than 2 in paths then it's easier to refactor to account for it.
      var href = this.router.url;
  
      var paths = this.getPaths();
  
      var role = this.roleService.role();
  
      if (!!staticContent && !!role) {
        languageSection = staticContent.Sections.find(x => x.Url == `/${this.translateService.currentLang}/`);
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/${this.translateService.currentLang}/${role}/`);
          if (!!privateSection) {
            category = privateSection.Categories.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/`);
          }
          if (!!category && !!category.Pages) {
            page = category.Pages.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/${paths[1]}/`);
          }
          else if (!!category.Modules){
            page = category;
          }
        }
  
        if (!!page && !!page.Modules) {
          var psModule = page.Modules.find(x => x.ModuleType == 'psModule');
  
          if (!!psModule) {
  
            if (!!psModule.Content && !!psModule.Content.Image) {
              return `${staticContent.CDN}${psModule.Content.Image}`;
            }
          }
        }
      }
      return undefined;
    }
  
    getHtmlBelowPsModule(): string {
      var staticContent = this.staticContent;
  
      var languageSection;
      var privateSection;
      var category;
      var page;
  
      //Do this so in case we have more a length of more than 2 in paths then it's easier to refactor to account for it.
      var href = this.router.url;
  
      var paths = this.getPaths();
  
      var role = this.roleService.role();
  
      if (!!staticContent && !!role) {
        languageSection = staticContent.Sections.find(x => x.Url == `/${this.translateService.currentLang}/`);
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/${this.translateService.currentLang}/${role}/`);
          if (!!privateSection) {
            category = privateSection.Categories.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/`);
          }
          if (!!category && !!category.Pages) {
            page = category.Pages.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${paths[0]}/${paths[1]}/`);
          }
          else if (!!category && !!category.Modules){
            page = category;
          }
        }
  
        if (!!page && !!page.Modules) {
          var psModuleIndex = page.Modules.findIndex(x => x.ModuleType == 'psModule');
          var modules;
          if (psModuleIndex != null && psModuleIndex != undefined) {
            //If index exists then we only want html modules after the defined psModule
            modules = page.Modules.slice(psModuleIndex)
          }
          else{
            modules = page.Modules;
          }
  
          var htmlModules = modules.filter(x => x.ModuleType == 'moduleHtml');
  
          if (!!htmlModules) {
            //The first html module is always the title so ignore it and only do it if there was no psModule as the psModule splice will have already removed it otherwise
            if (psModuleIndex == null || psModuleIndex == undefined) {
              htmlModules.shift();
            }
  
            var htmlPageString: string = "";
            htmlModules.forEach(x => htmlPageString = htmlPageString.concat(x.Html));
            htmlPageString = this.decodeHtml(htmlPageString);
            htmlPageString = this.replaceLinks(htmlPageString);
            return htmlPageString;
          }
        }
      }
      return '';
    }
  
    public getPsModuleContent(categoryPath: string, fullPath: string) {
      var languageSection;
      var privateSection;
      var category;
      var homepage;
  
      var staticContent = this.staticContent;
      var role = this.roleService.role();
  
      if (!!staticContent && !!role) {
        languageSection = staticContent.Sections.find(x => x.Url == `/${this.translateService.currentLang}/`);
        if (!!languageSection) {
          privateSection = languageSection.SubSections.find(x => x.Url == `/${this.translateService.currentLang}/${role}/`);
          if (!!privateSection) {
            category = privateSection.Categories.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${categoryPath}/`);
          }
          if (!!category && !!category.Pages) {
            homepage = category.Pages.find(x => x.Url == `/${this.translateService.currentLang}/${role}/${fullPath}/`);
          }
          else if (!!category.Modules){
            homepage = category;
          }
        }
  
        if (!!homepage && !!homepage.Modules) {
          var modules = homepage.Modules;
          var module = modules.find(x => x.ModuleType == "psModule");
  
          if (!!module) {
            return module.Content;
          }
          return undefined;
        }
      }
  
      return undefined;
    }
  
  
    private replaceLinks(htmlPageString: string) {
      var firstReg = /img src=\"/g;
      htmlPageString = htmlPageString.replace(firstReg, `img src=\"${this.cdn()}/`);
  
      var secondReg = /img src='\//g;
      htmlPageString = htmlPageString.replace(secondReg, `img src='${this.cdn()}/`);
  
      var thirdReg = /img src=\\\""/g;
      htmlPageString = htmlPageString.replace(thirdReg, `img src=\\\"${this.cdn()}/`);
  
      var fourthReg = /background-image:url\('\//g;
      htmlPageString = htmlPageString.replace(fourthReg, `background-image:url('${this.cdn()}/`);
  
      var pdfReg = /a href='\/media\//g;
      htmlPageString = htmlPageString.replace(pdfReg, `a href='${this.cdn()}/media/`);
  
      var pdfReg2 = /a href="\/media\//g;
      htmlPageString = htmlPageString.replace(pdfReg2, `a href=\"${this.cdn()}/media/`);
  
      //htmlPageString.replace(`${this.cdn()}/http`, "http");
      return htmlPageString;
    }
  
    private decodeHtml(html: string) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
  
    public loadAllCssJs() {
      var staticContent = this.staticContent;
      if (!!staticContent) {
        var axdFiles = staticContent.GlobalCss.split(";");
        axdFiles.forEach(x => {
          this.loadjscssfile(x, "css");
        });
        var jsAxdFiles = staticContent.GlobalJs.split(";");
        jsAxdFiles.forEach(x => {
          this.loadjscssfile(x, "js");
        });
      }
    }
    
    public loadjscssfile(filename, filetype) {
      if (filetype == "js") { //if filename is a external JavaScript file
        let node = document.createElement('script');
        node.src = `${this.cdn()}${filename}`;
        node.type = 'text/javascript';
        var scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
          if (!!scripts[i] && scripts[i].src === `${this.cdn()}${filename}`) {
            // scripts[i].remove();
            return;
          }
        }
        document.getElementsByTagName("head")[0].appendChild(node);
        window.dispatchEvent(new Event('th.domChanged'));
      }
      else if (filetype == "css") { //if filename is an external CSS file
        let fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", `${this.cdn()}${filename}`)
        var links = document.getElementsByTagName('link');
        for (let i = 0; i < links.length; i++) {
          if (!!links[i] && links[i].href === `${this.cdn()}${filename}`) {
            // links[i].remove();
            return;
          }
        }
        document.getElementsByTagName("head")[0].appendChild(fileref);
      }
    }
}